body {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  h2 {
      color: #23395B;
  }

.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }